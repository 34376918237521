import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../components/Layout/context";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "../../components/SEO"

function FeedbackForm() {
  const { lan, setLang } = useContext(AppContext);

  useEffect(() => {
    const languagekey = localStorage.getItem("lankey");
    if (languagekey === null) {
      localStorage.setItem("lankey", "en");
      setLang("en");
    }
  }, []);

    const data = useStaticQuery(graphql`
      query {
        allStrapiFeedbackPage {
          edges {
              node {
                locale
                name
                email
                latestEvent
                wentWell
                wentWrong
                improve
                overall
              }
            }
          }
        }
  `);
  const feedbackData = data.allStrapiFeedbackPage?.edges;
  const filteredData = feedbackData.filter(({ node }) => node.locale.includes(lan));
  const name = filteredData?.[0]?.node?.name
  const email = filteredData?.[0]?.node?.email
  const latestEvent = filteredData?.[0]?.node?.latestEvent
  const wentWell = filteredData?.[0]?.node?.wentWell
  const wentWrong = filteredData?.[0]?.node?.wentWrong
  const improve = filteredData?.[0]?.node?.improve
  const overall = filteredData?.[0]?.node?.overall

  const [experience, setExperience] = useState('')
  const [contactLater, setContactLater] = useState('')

  const [formData, setFormData] = useState({
        experience: "",
        well: "",
        wrong: "",
        improve: "",
        moreQuestions: "",
        email: ""
    })

  const handleChange = e => {
  const { name, value } = e.target
  setFormData({...formData, 
        [name]: value
    })
  }

  return (

    <div className="main-container text-white text-center md:lg:pb-[80px]">
      <SEO 
        title={`Happen Space | Feedback`}
      />
      <h1 className="pt-8 text-2xl md:text-5xl font-semibold text-white">
        Feedback 
      </h1>
			<div className=" blog-detail">
				<p className="py-10">
					Happen is on a development version. It's a work in progress but got feedback? Let us know!
				</p>
			</div>
			<div className="border border-[#A8A5A9] rounded-lg  px-10 py-10">
				<form method="POST" netlify-honeypot="bot-field" data-netlify="true" name="dynamic-feedback">
					<input type="hidden" name="form-name" value="dynamic-feedback"></input>
          <div className="place-items-center ">
            <div className="max-w-full mb-8">
              <label className="flex text-left mt-5">How was your experience with Happen?</label>
              <div className="form-radio">
                <label><input name="experience" type="radio" value="OK" onChange={event => setExperience(event.target.value)} /><span role="img" aria-label="grin" className="mr-2">😁</span>OK</label>
                <label><input name="experience" type="radio" value="NotOK" onChange={event => setExperience(event.target.value)}/><span role="img" aria-label="frowning_face" className="mr-2">☹️</span> Not OK</label>
                <label><input name="experience" type="radio" value="Suggestion" onChange={event => setExperience(event.target.value)}/><span role="img" aria-label="bulb" className="mr-2">💡</span>Suggestion</label>
              </div>
              <div id="wentWell" hidden={experience === "Suggestion" || experience === ""}>
                <h2 className="font-semibold text-left text-[18px] mt-5">Give us a compliment</h2>
                <label className="flex text-left mt-5">{ experience === "OK" ? wentWell : wentWrong}</label>
                <input
                  type="text"
                  id={ experience === "OK" ? "well" : "wrong"}
                  name={ experience === "OK" ? "well" : "wrong"}
                  value={experience === "OK" ? formData.well : formData.wrong}
                  onChange={handleChange}
                  className="mb-5 focus:outline-none w-full pb-[120px] pt-3 text-[#7D7880] placeholder-[#7D7880] pl-4 placeholder:text-base placeholder:text-primary placeholder:font-medium rounded-[7px] "
                />
              </div>
              <div id="suggestion" hidden={experience === ""}>
                  <h2 className="font-semibold text-left text-[18px] mt-5">Make a suggestion</h2>
                  <label className="flex text-left mt-5">{improve}</label>
                    <input
                      type="text"
                      id="improve"
                      name="improve"
                      className="mb-5 focus:outline-none w-full pb-[120px] pt-3 text-[#7D7880] placeholder-[#7D7880] pl-4 placeholder:text-base placeholder:text-primary placeholder:font-medium rounded-[7px] "
                    />
                    <label className="flex text-left mt-5">Can we contact you if we have more questions?</label>
                    <div className="form-radio">
                      <label><input name="more-questions" id="more-questions" type="radio" value="Yes" onChange={event => setContactLater(event.target.value)} checked={ contactLater === "Yes" } />Yes</label>
                      <label><input name="more-questions" id="more-questions" type="radio" value="No" onChange={event => setContactLater(event.target.value)} checked={ contactLater === "No" } />No</label>
                    </div>
                    <div id="contact" hidden={contactLater === "No" || contactLater === ""}>
                      <h2 className="font-semibold text-left text-[18px] mt-5">Contact</h2>
                      <label className="flex text-left mt-5">{email} *</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        required={contactLater === "Yes"}
                        className="mb-5 focus:outline-none w-full pb-[18px] pt-3 text-[#7D7880] placeholder-[#7D7880] pl-4 placeholder:text-base placeholder:text-primary placeholder:font-medium rounded-[7px]  "
                      />
                    </div>
                    <div id="sendButton" hidden={contactLater === ""}>
                      <button className="flex items-center rounded-[7px] font-semibold text-white  header-btn capitalize px-8 py-2 mt-3" type="submit">
                        send
                      </button>
                    </div>
                  </div>
					</div>
				</div>
        </form>
			</div>
    </div>
  )
}

export default FeedbackForm;
