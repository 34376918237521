import React from "react";
import Index from "../views/FeedbackForm";
import Layout from "../components/Layout/Layout";


const FeedbackPage = () => {

  return (
    <div className="bg-bgBlack">
      <Layout>
        <Index />
      </Layout>
    </div>
  );
};

export default FeedbackPage;
